@font-face {
    font-family: "JetBrains Mono";
    font-weight: 700;
    src: url("/fonts/JetBrainsMono-Bold.woff2") format("woff2"),
    url("/fonts/JetBrainsMono-Bold.woff") format("woff");
}

@font-face {
    font-family: "JetBrains Mono";
    font-weight: 400;
    src: url("/fonts/JetBrainsMono-Regular.woff2") format("woff2"),
    url("/fonts/JetBrainsMono-Regular.woff") format("woff");
}