#visualizerWrapper {
    margin-left: 32px;
}

#title {
    margin: 50px auto 1em;
    max-width: 70%;
    text-align: center;
}

#field {
    background-color: #333;
}
.attacker {
    color: orange;
}
.defender {
    color: teal;
}

.instruction {
    max-width: 90%;
    margin: 1em auto;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(5, auto);
}
.instruction .only3dcontrol {
    display: none;
}
.instruction.only3d {
    grid-template-columns: repeat(6, auto);
}
.instruction.only3d .only3dcontrol {
    display: block;
}
.instruction.only3d .only2dcontrol {
    display: none;
}
.streak {
    text-decoration: line-through;
}