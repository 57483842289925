.table {
    border-collapse: collapse;
    font-size: var(--font-size-S);
    width: 100%;
}

.table tbody {
    vertical-align: top;
}

.table td,
.table th {
    text-align: left;
    padding: 10px;
    border-bottom: var(--block-border);
}

.Win {
    color: var(--win);
}
.Lose {
    color: var(--lose);
}
.Draw {
    color: var(--draw);
}
