:root {
    --text: #fff;
    --additional-text: #eaecef;
    --gray: #2F3336;
    --link: #00bfff;
    --error: #E50000;
    --lose: var(--error);
    --orange: orange;
    --win: #12E500;
    --draw: #E5B300;

    --background: #000;
    --block-background: rgb(21, 24, 28);
    --error-background: #400;

    --font-size-S: 14px;
    --font-size: 16px;
    --font-size-L: 22px;
    --font-size-XL: 30px;
    --font-size-XXL: 44px;
    --font-size-XXXL: 64px;
    --nav-link-size: 18px;

    --vertical-gap: 7vh;
    --horizontal-gap: 5vw;

    --block-border: solid 1px var(--gray);
    --input-border: solid 2px var(--gray);
    --error-border: solid 1px #800;

    --mobile-width: 600px;
    --container-width: 1012px;
    --wide-container-size: 1400px;
}

@media (max-width: var(--mobile-width)) {
    :root {
        --font-size-S: 10px;
        --font-size: 12px;
        --font-size-L: 16px;
        --font-size-XL: 21px;
        --font-size-XXL: 28px;
        --font-size-XXXL: 46px;
    }
}

body {
    margin: 0;
    font-family: "JetBrains Mono", Consolas, monospace;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: var(--font-size);
    line-height: 1.5;
    color: var(--text);
    background-color: var(--background);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
    color: var(--link);
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

@media (max-width: var(--mobile-width)) {
    br {
        content: ' ';
    }

    br:after {
        content: ' ';
    }
}

h1 {
    font-size: var(--font-size-XXL);
    font-weight: bold;
    margin: 0 auto 0.5em;
    text-align: center;
}

h2 {
    font-size: var(--font-size-XL);
    font-weight: bold;
    margin: 0 auto 1em;
    text-align: center;
}

h3 {
    font-size: var(--font-size-L);
    font-weight: bold;
    margin: 0 auto 0.5em;
}
