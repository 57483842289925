.form {
    background: var(--block-background);
    box-sizing: border-box;
    border: var(--block-border);
    border-radius: 14px;
    padding: 1.5em 2em;
}

.form label {
    font-size: var(--font-size);
}

.form input {
    border: var(--input-border);
    border-radius: 14px;
    box-sizing: border-box;
    background: var(--background);
    color: var(--text);
    font-family: "JetBrains Mono", Consolas, monospace;
    font-size: var(--font-size);
    margin: 0;
    padding: 0.5em 1em;
    width: 100%;
}

.form textarea {
    border: var(--input-border);
    border-radius: 14px;
    box-sizing: border-box;
    background: var(--background);
    color: var(--text);
    font-family: "JetBrains Mono", Consolas, monospace;
    font-size: var(--font-size);
    margin: 0;
    padding: 0.5em 1em;
    width: 100%;
}

.form select {
    appearance: none;
    border: var(--input-border);
    border-radius: 14px;
    box-sizing: border-box;
    background: var(--background);
    color: var(--text);
    font-family: "JetBrains Mono", Consolas, monospace;
    font-size: var(--font-size);
    margin: 0;
    padding: 0.5em 1em;
    width: 100%;
}

.form button {
    border: var(--input-border);
    border-radius: 14px;
    box-sizing: border-box;
    background: var(--block-background);
    color: var(--text);
    cursor: pointer;
    font-family: "JetBrains Mono", Consolas, monospace;
    font-size: var(--font-size);
    margin: 0;
    padding: 0.5em 1em;
}

.form button:hover {
    background: rgb(47, 51, 54);
}
